import { util } from "@/tools/util.js";
import { tableHeight } from "@/tools/resizeTable.js";
import { mapState } from 'vuex'
export const getList = {
  data () {
    return {
      query: {
        offset: 1,
        limit: 10
      },
      requst: null,
      permission: {},
      showLoading: false,
      tableData: [],
      pageTotal: 0,
      // tableHeight: 'calc(100% - 100px)'
      tableHeight: 0,

    }
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      treeList:state=>state.app.treeList,
      daPingList:state=>state.app.daPingList
    })
  },
  watch: {
    sidebar (val) {
			setTimeout(()=>{
        if(this.$refs.table) this.$refs.table.doLayout();
      },400)
		}
  },
  // 
  filters: {
    formatDate (value) {
      return util.formatTime(value, 'yyyy-mm-dd hh:MM:ss')
    },
    formatDateNew (value) {
      return util.formatTime(value, 'yyyy-mm-dd')
    },
    getStateName(value, stateList) {
      var array = stateList.filter((obj) => {
        return value === obj.value;
      });
      if (array.length !== 0) {
        return array[0].label;
      } else {
        return "-";
      }
    },
  },
  created () {
    this.$nextTick(() => {
      if (this.requst) this.getData();
    })
  },
  mounted () {
    this.pageTableHeight()
  },
  beforeDestroy () {
    window.onresize = null
  },
  methods: {
    // 处理table高度
    pageTableHeight () {
      this.$nextTick(() => {
        tableHeight(this).then(res => {
          this.tableHeight = res
          window.onresize = () => {
            tableHeight(this).then(res2 => {
              this.tableHeight = res2
            })
          }
        })
      })
    },
    //更新数据
    async refreshData () {
      this.handleCurrentChange(1)
    },
    //更新数据
    async refresCurrentPagehData () {
      this.handleCurrentChange( this.query.offset)
    },
    //删除后调用
    async delRefresh (index) {
      this.tableData.splice(index, 1)
      this.pageTotal -= 1
      if (!this.tableData.length) {
        this.handleCurrentChange((this.query.offset - 1) || 1)
      }
    },
    //更改每页显示条数
    async handleSizeChange (val) {
      this.query.limit = val;
      this.query.offset = 1;
      this.getData();
    },
    //设置当前页
    async handleCurrentChange (val) {
      this.query.offset = val;
      this.getData();
    },
    //获取列表数据
    async getData () {
      try {
        console.log(this.query)
        this.showLoading = true;
        let data = await this.requst.module[this.requst.url](this.query);
        if (data.code === 200) {
          this.tableData = data.data;
        } else {
          this.tableData = [];
        }
        this.pageTotal = data.total;
        // this.permission = util.formatLsp(data.lsp);
      } catch (error) {
        this.tableData = [];
        console.log(error);
      } finally {
        this.showLoading = false;
        this.handlePermission();
        this.pageTableHeight()
        setTimeout(()=>{
          if(this.$refs.table) this.$refs.table.doLayout();
        },400)
      }

    },
    handlePermission(){
      const url = this.$route.fullPath;
      console.log("有效期",url);
      this.treeList.forEach(i=>{
        if(i.children){
          i.children.forEach(e=>{
            if(e.attributes.url===url) this.permission = util.formatLsp(e.attributes.permsList);
          })
        }
      })
      console.log('权限字断======>>>',this.permission)
    },
    //添加根据路由查看权限(权限需要外部传)
    handlePermissionNew(url){
      this.treeList.forEach(i=>{
        if(i.children){
          i.children.forEach(e=>{
            if(e.attributes.url===url) this.permission = util.formatLsp(e.attributes.permsList);
          })
        }
      })
      console.log('权限字断======>>>',this.permission)
    },
    //添加根据路由查看权限(权限需要外部传)
    handlePermissionDaPing(url){
      this.daPingList.forEach(i=>{
        if(i.children){
          i.children.forEach(e=>{
            if(e.attributes.url===url) this.permission = util.formatLsp(e.attributes.permsList);
          })
        }
      })
      console.log('权限字断======>>>',this.permission)
    }
    
  }
}