var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        modal: false,
        visible: _vm.isShow,
        "destroy-on-close": true,
        fullscreen: "",
      },
      on: { close: _vm.changeClosed },
    },
    [
      _c("div", {
        staticClass: "html-content editor-custom",
        domProps: { innerHTML: _vm._s(_vm.content) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }