<template>
  <el-dialog
    :title="title"
    :modal="false"
    :visible="isShow"
    @close="changeClosed"
    :destroy-on-close="true"
    fullscreen
  >
    <div class="html-content editor-custom" v-html="content" ></div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "提示",
    },
    content: [String, Number],
    isShow: [Boolean],
  },
  model: {
    prop: "isShow", //指向props的参数名
    event: "change", //事件名称
  },
  methods:{
changeClosed(){
    this.$emit('change',false)
}
  }
};
</script>

<style scoped>
/* table 样式 */
/* .editor-custom table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.editor-custom table td,
.editor-custom table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
.editor-custom table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
} */

/* blockquote 样式 */
/* .editor-custom blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
} */

/* code 样式 */
.editor-custom code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
.editor-custom pre code {
  display: block;
}

/* ul ol 样式 */
/* .editor-custom ul, .editor-custom ol {
  margin: 10px 0 10px 20px;
} */
.editor-custom ul li {
  list-style-type: disc !important;
}
.editor-custom ol li {
  list-style-type: decimal !important;
}

.html-content{
white-space: pre-wrap;
}
.html-content>>>img{
  max-width: 100%;
}

</style>